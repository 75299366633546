import React, {useState, forwardRef, useImperativeHandle, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Modal, Overlay, Spinner, Tooltip} from 'react-bootstrap';

import {useCallback} from 'react';
import axios from "axios";
import {formatNumber} from "chart.js/helpers";
import {CopyToClipboard} from "react-copy-to-clipboard/src"; // Import at the top of your file
import {IMaskInput} from 'react-imask';
import ReCAPTCHA from "react-google-recaptcha"

const {REACT_APP_STAGE} = process.env;

const DoacaoConsultaModal = forwardRef((props, ref) => {

    const [donateConsultModal, setConsultDonateModal] = useState(false);

    const recaptchaRef = React.createRef();

    const [page, setPage] = useState(1);

    const [botaoConsultar, setBotaoConsultar] = useState(false);

    const [emailError, setEmailError] = useState(false);

    const [cpf_cnpj, setCpfCnpj] = useState("");
    const [email, setEmail] = useState("");

    const [numbers, setNumbers] = useState([]);

    const [displayError, setDisplayError] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

    const numbersTags = numbers.map((number) => {
        return (
            <span className="badge bg-secondary m-1">{number}</span>
        )
    });

    // Validar e-mail
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    // Validar CPF ou CNPJ
    const validateCpfCnpj = (value) => {
        const cpfCnpj = value.replace(/\D/g, '');
        if (cpfCnpj.length === 11) {
            return validateCpf(cpfCnpj);
        } else if (cpfCnpj.length === 14) {
            return validateCnpj(cpfCnpj);
        } else if (cpfCnpj.length < 11) {
            return true;
        } else {
            return false;
        }
    }

    function validateCnpj(cnpj) {
        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (!cnpj || cnpj.length !== 14
            || cnpj === "00000000000000"
            || cnpj === "11111111111111"
            || cnpj === "22222222222222"
            || cnpj === "33333333333333"
            || cnpj === "44444444444444"
            || cnpj === "55555555555555"
            || cnpj === "66666666666666"
            || cnpj === "77777777777777"
            || cnpj === "88888888888888"
            || cnpj === "99999999999999")
            return false
        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0, tamanho)
        var digitos = cnpj.substring(tamanho)
        var soma = 0
        var pos = tamanho - 7
        for (var i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--
            if (pos < 2) pos = 9
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
        if (resultado != digitos.charAt(0)) return false;
        tamanho = tamanho + 1
        numeros = cnpj.substring(0, tamanho)
        soma = 0
        pos = tamanho - 7
        for (var i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--
            if (pos < 2) pos = 9
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
        if (resultado != digitos.charAt(1)) return false
        return true;
    }

    function validateCpf(cpf) {
        cpf = cpf.replace(/[^\d]+/g, '');
        if (cpf === '') return false;
        // Elimina CPFs invalidos conhecidos
        if (cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999")
            return false;
        // Valida 1o digito
        let add = 0;
        for (let i = 0; i < 9; i++)
            add += parseInt(cpf.charAt(i)) * (10 - i);
        let rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev !== parseInt(cpf.charAt(9)))
            return false;
        // Valida 2o digito
        add = 0;
        for (let i = 0; i < 10; i++)
            add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        return rev === parseInt(cpf.charAt(10));
    }

    useImperativeHandle(ref, () => ({
        handleModalOpen() {
            setConsultDonateModal(true);
            setPage(1);
            setEmail("");
            setCpfCnpj("");
        }
    }));

    function handleModalClose() {
        setConsultDonateModal(false);
    }

    const nav = useNavigate();

    // submit form using axios

    const FormSubmit = (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setEmailError(true);
            return;
        }
        setEmailError(false);

        if (!validateCpfCnpj(cpf_cnpj)) {
            return;
        }

        setBotaoConsultar(true);

        recaptchaRef.current.executeAsync().then(recaptchaValue => {
            const data = {
                "Document": cpf_cnpj.replace(/\D/g, ''),
                "Email": email,
                "CauseCod": 3,
                "g-recaptcha-response": recaptchaValue,
            }
            axios.post(`https://${REACT_APP_STAGE}.ajudeumacausa.com.br/v1/raffle/GetMyRaffle`, data)
                .then(res => {

                    setNumbers(res.data.Details.map((number) => {
                        return number.Number;
                    }));

                    setPage(2);

                }).catch((error) => {

                // caso erro 404
                if (error.response.status === 404) {
                    setDisplayError(true);
                    setDisplayErrorMessage("Nenhum número encontrado para o CPF/CNPJ e e-mail informados.");

                    setTimeout(() => {
                        setDisplayError(false);
                        setDisplayErrorMessage("");
                    }, 5000);
                    return;
                }

            }).finally(() => {
                setBotaoConsultar(false)
            });
        });

    }

    return (
        <Modal className="fade modal-wrapper" id="modalConsultDonate" show={donateConsultModal}
               onHide={setConsultDonateModal}
               backdrop="static" centered>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Consultar números da doação</h4>
                    <button type="button" className="btn-close" onClick={() => handleModalClose()}><i
                        className="flaticon-close"></i></button>
                </div>
                <div className={page === 1 ? "modal-body" : "d-none"}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contact-form">
                                <form id="contactForm" onSubmit={FormSubmit}>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        size="invisible"
                                        sitekey="6LeYASkpAAAAAEqY8ZBGuL-2Y2GRTPR1pF-fhQjl"
                                    />
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <input name="dzEmail" required type="text"
                                                           className={emailError ? "form-control is-invalid" : "form-control"}
                                                           placeholder="Email"
                                                           value={email} onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>
                                                <div className="invalid-feedback">
                                                    E-mail inválido
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <IMaskInput
                                                        mask={["000.000.000-00", "00.000.000/0000-00"]}
                                                        className={validateCpfCnpj(cpf_cnpj) ? "form-control" : "form-control is-invalid"}
                                                        value={cpf_cnpj} onChange={(e) => setCpfCnpj(e.target.value)}
                                                        onComplete={(e) => setCpfCnpj(e)}
                                                        placeholder="CPF/CNPJ"
                                                        name="dzCpfCnpj" required
                                                    ></IMaskInput>
                                                    <div className="invalid-feedback">
                                                        {cpf_cnpj.length > 14 ? "CNPJ inválido" : "CPF inválido"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mb-0 text-center py-2">
                                            <div
                                                className={(displayError ? "alert alert-danger" : 'd-none') + " fade show"}>{displayErrorMessage}</div>
                                            <Button variant="primary" type="submit" name="submit"
                                                    className="btn btn-primary btn-block" disabled={botaoConsultar}>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className={botaoConsultar ? '' : 'd-none'}
                                                />
                                                {' '}
                                                Consultar
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={page === 2 ? "modal-body" : "d-none"}>
                    <div className="row">
                        <div className={"col-lg-12"}>
                            <div className={"alert alert-success text-center"} role="alert">
                                <p className={"mb-0"}>Seus números da doação são</p>
                            </div>
                        </div>
                        <div className={"col-lg-12"}>
                            {numbersTags}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
});
export default DoacaoConsultaModal