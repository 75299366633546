import React, {useRef} from 'react';
import {Navigate, Route, Routes, Outlet} from 'react-router-dom';

import ScrollToTop from './../layouts/ScrollToTop';
import Header from './../layouts/Header2';
import Footer from './../layouts/Footer2';
import Error from './Error';
import UnderMaintenance from './UnderMaintenance';
import ComingSoon from './ComingSoon';
import FundraiserDetail from './FundraiserDetail';

import RedirectWithCookies from "./RedirectWithCookies";
import FundraiserDetail_VisionPro from "./FundraiserDetail_VisionPro";
import FundraiserDetail_Honda from "./FundraiserDetail_Honda";
import FundraiserDetail_RS from "./FundraiserDetail_RS";

function Index() {

    return (
        <>
            <Routes>
                <Route path='/' element={<Navigate to="/sorteio-pelos-animais-instituto-caramelo-honda-city"/>}/>
                <Route path='/error-404' element={<Error/>}/>
                <Route path='/under-maintenance' exact element={<UnderMaintenance/>}/>
                <Route path='/coming-soon' exact element={<ComingSoon/>}/>
                <Route path='/sorteio-pelos-animais-instituto-caramelo' element={<MainLayout/>}>
                    <Route index element={<FundraiserDetail/>}/>
                </Route>
                <Route path='/sorteio-pelos-animais-instituto-caramelo-apple-vision-pro-2024' element={<MainLayout/>}>
                    <Route index element={<FundraiserDetail_VisionPro/>}/>
                </Route>
                <Route path='/sorteio-pelos-animais-instituto-caramelo-honda-city' element={<MainLayout/>}>
                    <Route index element={<FundraiserDetail_Honda/>}/>
                </Route>
                <Route path='/ajude-o-rs' element={<MainLayout/>}>
                    <Route index element={<FundraiserDetail_RS/>}/>
                </Route>
                <Route path="*" element={<RedirectWithCookies replace/>}/>
            </Routes>
            <ScrollToTop/>
        </>
    )
}

function MainLayout() {
    return (
        <div className="page-wraper">
            <Header/>
            <Outlet/>
            <Footer/>
        </div>
    )
}

export default Index;
